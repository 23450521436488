<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div>
        <div class="top">
          <h2 class="title">
            <div class="title_icon"></div>欠费离场规则
          </h2>
          <el-form
            label-position="right"
            label-width="120px"
            :model="formInline"
            style="width: 300px;margin-left:50px"
          >
            <el-form-item label="规则名称：" prop="debtLeaveRuleName">
              <span>{{formInline.debtLeaveRuleName}}</span>
            </el-form-item>
            <el-form-item label="编码：" prop="debtLeaveRuleCode">
              <span>{{formInline.debtLeaveRuleCode}}</span>
            </el-form-item>
            <el-form-item label="描述：" prop="description" style="width:550px;word-break: break-all;">
              <el-input type="textarea" :readonly="true" :rows="8" v-model="formInline.description"></el-input>
            </el-form-item>
          </el-form>
          <el-form
            label-position="right"
            label-width="120px"
            :model="formInline"
            style="width: 300px;margin-left:50px;"
          >
            <el-form-item label="所属商户：" prop="operationManagerName">
              <span>{{formInline.operationName}}</span>
            </el-form-item>
            <!-- <el-form-item label="操作人：" prop="operationManagerName">
              <span>{{formInline.operationManagerName}}</span>
            </el-form-item> -->
            <el-form-item label="操作时间：" prop="updatedTime">
              <span>{{formInline.updatedTime}}</span>
            </el-form-item>
            <el-form-item label="生效日期：">
              <span>{{(formInline.effectiveStartDate || '') + '-' +  (formInline.effectiveEndDate || '')}}</span>
            </el-form-item>
          </el-form>
        </div>
        <div class="bottom">
          <el-form
            label-position="right"
            label-width="140px"
          >
            <div class="b_form">
              <h2 class="title">
                <div class="title_icon"></div>车场欠费规则
              </h2>
              <el-form-item label="车场欠费总金额 ≤">
                <span>{{source.parkDebtTotalMoney}}</span>
                <span>&nbsp;&nbsp;元</span>
              </el-form-item>    
              <br />
              <div v-for="(detail, index) in source.periodRules" :key="index">
                <el-form-item :label="'欠费离场时段' + (index +  1) +'：'">
                  <span>{{detail.startTime + '-' + detail.endTime}}</span>
                </el-form-item>
              </div>
            </div>
            <!-- 3 -->
            <div class="b_form">
              <h2 class="title">
                <div class="title_icon"></div>车场欠费规则
              </h2>
              <el-form-item label="单次欠费金额 ≤">
                <span>{{source.preMaxMoney}}</span>
                <span>&nbsp;&nbsp;元</span>
              </el-form-item>
              <el-form-item label="欠费总次数 ≤">
                <span>{{source.totalTimes}}</span>
                <span>&nbsp;&nbsp;次</span>
              </el-form-item>
              <el-form-item label="欠费总金额 ≤">
                <span>{{source.totalMoney}}</span>
                <span>&nbsp;&nbsp;元</span>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div style="text-align:center">
          <el-button @click="$router.go(-1)">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "outSeeRule",
  data() {
    return {
      debtLeaveRuleId: "",
      formInline: {
        debtLeaveRuleName: "",
        debtLeaveRuleCode: "",
        description: "",
        operationName: "",
        updatedTime: "",
        effectiveStartDate: "",
        effectiveEndDate: "",
      },
      source: []
    };
  },
  methods: {
    showFormatter(data) {
      return dateFormat(data);
    },
    getRuleData() {
      this.$axios.get("/acb/2.0/bacb/debtLeaveRule/getById/" + this.debtLeaveRuleId).then(res => {
        if (res.state == 0) {
          let {
            debtLeaveRuleName,
            debtLeaveRuleCode,
            description,
            operationName,
            updatedTime,
            effectiveStartDate,
            effectiveEndDate
          } = res.value;
          this.formInline = {
            debtLeaveRuleName,
            debtLeaveRuleCode,
            description,
            operationName,
            updatedTime,
            effectiveStartDate,
            effectiveEndDate
          };
          let copyData = JSON.parse(res.value.property);
          this.source = copyData;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    }
  },
  components: {},
  mounted() {
    this.debtLeaveRuleId = this.$route.query.debtLeaveRuleId;
    this.getRuleData();
  },
  computed: {}
};
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;

  .el-form-item {
    margin: 0;
  }
}

.top {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 20px;
}

.middle {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 10px 0;
  margin: 10px 0;
}

.bottom {
  .b_form {
    border: 1px solid #e1e1e1;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0;
  }
}

.feeDis {
  margin-left: 20px;
}
</style>
